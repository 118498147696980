.App {
    text-align: center;
    width: 100%;
}

.App:has(#rsvp_form) {
    overflow: hidden;
}

#main_nav {
    background-color: hsla(327, 64%, 25%, 0.4);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    height: 80px;
    color: white;
    z-index: 10;
}

#main_nav > div {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
    gap: 10px;
}

#main_nav .left {
    position: absolute;
}

#main_nav .right {
    display: flex;
    position: absolute;
    align-items: flex-end;
    right: 0;
}

#main_nav a {
    color: white;
    text-decoration: none;
    height: 100%;
    padding: 20px 30px;
}

.home_link {
    font-family: "Great Vibes", cursive;
    font-size: 45px;
    font-weight: 400;
    transform: translateY(2px);
}

#main_nav .right a {
    font-size: 20px;
    font-family: "Cinzel Decorative", serif;
    font-weight: 600;
}

.button_rsvp {
    background: transparent;
    border: 2px solid white;
    padding: 12px 17px;
    font-size: 18px;
    color: white;
    font-weight: 800;
    font-family: "Cinzel Decorative", serif;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
    transition: all 0.1s;
}

.button_rsvp:hover {
    background: white;
    color: #691744;
}

@media screen and (max-width: 700px) {
    #main_nav {
        height: 60px;
    }

    #main_nav .left {
        padding-left: 0 !important;
    }

    .home_link {
        font-size: 35px;
        padding-left: 15px !important;
    }

    .button_rsvp {
        padding: 10px 14px;
        font-size: 15px;
        margin-right: 0px;
    }

    /* #main_nav .right {
        visibility: hidden;
    } */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
