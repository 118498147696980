.section_details.reverse {
    display: flex;
    background: #6a1745;
    color: #e4d4dc;
}

.section_details.reverse .section_content {
    flex-direction: row-reverse;
}

.section_details button {
    background: transparent;
    border: 2px solid #e5d4dc;
    padding: 12px 17px;
    font-size: 18px;
    color: #e5d4dc;
    font-weight: 800;
    font-family: "Cinzel Decorative", serif;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
    transition: all 0.1s;
}

.section_details button:hover {
    background: #e5d4dc;
    border-color: #e5d4dc;
    color: #691744;
}

/* .section_details.rsvp p {
    font-family: "Cinzel", serif;
} */


@media screen and (max-width: 700px) {
    .section_details.reverse .section_content {
        flex-direction: column;
    }
}
