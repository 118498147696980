.section_invitation {
    --padding: 10%;
    --envelope-scale: 0.7;
    --invitation-scale: 1.8;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #6a1745;
    text-align: left;
    padding: var(--padding);
    overflow: hidden;
}

.envelope_wrapper {
    position: relative;
    max-width: min(800px, 100%);
    max-height: 60vh;
    width: 100%;
    height: 100%;
    aspect-ratio: 10/7;
}

.envelope_wrapper * {
    max-height: 60vh;
    max-width: min(800px, 100%);
    transition-duration: 1s;
    position: relative;
}

.front,
.back {
    position: relative;

    width: 100%;
    height: 100%;

    background: transparent;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.56);

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.front {
    --transform-state: perspective(1500px) rotateY(0deg);
    -webkit-transform: var(--transform-state);
    -moz-transform: var(--transform-state);
    -ms-transform: var(--transform-state);
    -o-transform: var(--transform-state);
    transform: var(--transform-state);

    z-index: 5;
}

.open_me {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    cursor: pointer;
    padding: 10px 15px 7px 15px;
    background: rgba(255, 255, 255, 0.7);
    color: #6a1745;
    border-radius: 10px;
    font-family: "Cinzel Decorative", serif;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3);
}

.back {
    --transform-state: perspective(1500px) rotateY(-180deg);
    -webkit-transform: var(--transform-state);
    -moz-transform: var(--transform-state);
    -ms-transform: var(--transform-state);
    -o-transform: var(--transform-state);
    transform: var(--transform-state);

    position: absolute;
    top: 0;
    left: 0;
}

.envelope_wrapper.isActive .back {
    --transform-state: perspective(1500px) rotateY(0deg);
}

.envelope_wrapper.isActive .front {
    --transform-state: perspective(1500px) rotateY(180deg);
}

.back > * {
    position: absolute;
    isolation: isolate;
}

.envelope_back {
    z-index: 4;
    width: 100%;
    height: 100%;
}

.envelope_back > * {
    width: 100%;
    height: 100%;
}

.envelope_front {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.envelope_front.interior {
    z-index: 2;
}

.envelope_front.exterior {
    z-index: 4;
}

.invitation {
    max-width: 120% !important;
    height: 120% !important;
    top: -10%;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
    z-index: 3;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.6);
}

.envelope_wrapper.isActive {
    animation: envelope-move 1s forwards;
}

.envelope_wrapper.isActive .invitation {
    animation: invitation-animation 1s forwards 1.5s;
}

@keyframes envelope-move {
    0% {
    }
    100% {
        transform: translateY(23%) scale(var(--envelope-scale)) rotateZ(40deg) translateX(1.5%);
    }
}

@keyframes invitation-animation {
    0% {
        top: -10%;
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);
        z-index: 3;
    }
    50% {
        top: -50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg)
            scale(var(--invitation-scale)) rotateZ(-40deg) translateX(-15%);
        z-index: 7;
    }
}

.flap_wrapper {
    position: absolute;
    top: 0;
    height: 85%;
    width: 100%;
    z-index: 6;
}

.envelope_wrapper.isActive .flap_wrapper {
    animation: flap-animation 0.5s forwards 1s;
}

@keyframes flap-animation {
    0% {
        z-index: 6;
    }

    100% {
        z-index: 2;
    }
}

.flap_wrapper > * {
    position: absolute;
    object-fit: fill;
    width: 100%;

    background: transparent;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flap_wrapper .flap_interior {
    top: 0;
    --transform-state: perspective(1500px) rotateY(180deg) rotateX(180deg);
    -webkit-transform: var(--transform-state);
    -moz-transform: var(--transform-state);
    -ms-transform: var(--transform-state);
    -o-transform: var(--transform-state);
    transform: var(--transform-state);
    visibility: hidden;
}

.flap_wrapper .flap_exterior {
    top: 0;
    --transform-state: perspective(1500px) rotateY(0deg);
    -webkit-transform: var(--transform-state);
    -moz-transform: var(--transform-state);
    -ms-transform: var(--transform-state);
    -o-transform: var(--transform-state);
    transform: var(--transform-state);
    z-index: 7 !important;
}

.envelope_wrapper.isActive .flap_exterior {
    animation: flap-animation-exterior 0.5s forwards 1s;
}

@keyframes flap-animation-exterior {
    0% {
        transform: perspective(1500px) rotateX(0deg);
        transform-origin: top;
    }

    100% {
        transform-origin: top;
        transform: perspective(1500px) rotateX(180deg);
    }
}

.envelope_wrapper.isActive .flap_interior {
    animation: flap-animation-interior 0.5s forwards 1s;
    z-index: 2;
}

@keyframes flap-animation-interior {
    0% {
        transform: perspective(1500px) rotateY(180deg) rotateX(0deg);
        transform-origin: top;
        visibility: visible;
    }
    100% {
        transform-origin: top;
        transform: perspective(1500px) rotateY(180deg) rotateX(-180deg);
        visibility: visible;
    }
}

@media screen and (max-width: 500px) {
    .section_invitation {
        --padding: 180px 25px;
        --envelope-scale: 0.8;
        --invitation-scale: 2.1;
    }

     .open_me {
        font-size: 25px;
     }
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
    .section_invitation {
        --padding: 180px 25px 200px 25px;
        --envelope-scale: 0.7;
        --invitation-scale: 2.0;
    }
}
