/* CSS */

.rsvp_form_container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    inset: 0;
    background: #ffffff00;
    backdrop-filter: blur(10px);
    font-family: "Cinzel Decorative", serif;
    color: white;
    animation: 150ms ease-out 0s 1 fadeIn;
}

#rsvp_form {
    position: absolute;
    margin: 0 auto;
    width: 85%;
    max-width: 700px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(106, 23, 69);
    backdrop-filter: blur(10px);
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
}

#rsvp_form h2 {
    text-align: center;
    font-weight: 800;
    margin-top: 40px;
    margin-bottom: 40px;
}

#rsvp_form form {
    display: flex;
    flex-direction: column;
}

#rsvp_form label {
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 800;
}

#rsvp_form input,
#rsvp_form textarea {
    border: 1px solid rgb(63, 13, 40);
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    background: #e5d4dc;
    color: rgb(63, 13, 40);
    font-family: "Cinzel", serif;
    font-weight: 700;
}

#rsvp_form button {
    padding: 15px 25px;
    background-color: rgb(63, 13, 40);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 22px;
    font-family: "Cinzel Decorative", serif;
    font-weight: 600;
}

#rsvp_form button:hover {
    background-color: rgb(58, 13, 37);
}

#rsvp_form .close_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
}

#rsvp_form .rsvp_buttons {
    width: 100%;
}

#rsvp_form #extraDetails {
    height: 70px;
}

#rsvp_form .error_message {
    font-weight: 800;
    font-size: 16px;
}

.notifications_container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
}
.notifications_container > * {
    margin: 0 !important;

}

#notificationsEnabled {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 10px !important;
    /* accent-color: #e5d4dc; */
}

@media screen and (max-width: 700px) {
    .rsvp_form_container {
        width: 100%;
    }

    #rsvp_form {
        height: calc(100% - 50px);
        width: calc(100% - 40px);
        border-radius: 0;
    }

    #rsvp_form label {
        font-size: 14px;
    }

    #rsvp_form input,
    #rsvp_form textarea {
        font-size: 16px;
    }
}
